[id^=react-select-] {
  .custom-react-select {
    background-color: var(--kt-form-select-bg);
    color: var(--kt-input-solid-color);
    //border: none

    &.disabled {
      background-color: var(--kt-form-select-disabled-bg);
      color: var(--kt-dropdown-link-disabled-color);
    }
  }

  .custom-react-multi-select {
    background-color: var(--kt-form-select-bg);
    color: var(--kt-input-solid-color);

    &.disabled {
      background-color: var(--kt-form-select-disabled-bg);
      color: var(--kt-dropdown-link-disabled-color);
    }
  }

  .custom-option {
    background-color: var(--kt-form-select-bg);
    color: var(--kt-input-solid-color);

    &.focused {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }
}
