//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";

.modal-backdrop-nested, .modal:has(.modal-nested) {
  z-index: 1057 !important; // default modal z-index is 1055
}

.site-report-tooltip {
  white-space: pre-line;
  font-size: $font-size-base;
  max-width: 100%;
  color: var(--kt-tooltip-color);
  background-color: var(--kt-tooltip-bg);
  border-radius: .475rem;
  opacity: var(--kt-tooltip-opacity);
  box-shadow: var(--kt-tooltip-box-shadow);
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
  position: absolute;
}

.site-report-tooltip table td {
  text-align: right;
  padding-left: 5px;
}

.site-report-tooltip .tr-tier-3 td {
  padding-bottom: .5rem !important;
}

.site-report-tooltip .td-amount {
  padding-left: 8px;
}
