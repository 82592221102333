.code-snippet {
  background-color: var(--kt-gray-100);
  border: 1px solid var(--kt-gray-300)!important;
  border-radius: 0.475rem !important;
  margin: 0!important;
}

.tooltip-nowrap {
  .tooltip-inner {
    max-width: none;
  }
}
