.apexcharts-tooltip {
  z-index: 9999!important;
}

#apexcharts-site-ggr .apexcharts-tooltip {
  max-height: 40vh;
  flex-wrap: wrap;
}

.apexcharts-menu {
  background-color: var(--kt-body-bg)!important;
}
